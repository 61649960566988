<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h1 class="text-3xl font-bold mb-6 text-purple-800">Docenten</h1>
    
    <div class="bg-white shadow rounded-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Voor Docenten</h2>
      <p class="text-gray-700 mb-4">
        Welkom bij het ROC Nijmegen docentenportaal. Hier vindt u alle informatie en tools die u nodig heeft om uw lessen te verrijken en uw studenten optimaal te begeleiden.
      </p>
      <h3 class="text-xl font-semibold mb-2 text-purple-700">Wat kunt u hier vinden?</h3>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Lesmateriaal en curricula voor verschillende opleidingen</li>
        <li>Informatie over professionele ontwikkelingsmogelijkheden</li>
        <li>Tools voor het beheren van studentenvoortgang en beoordelingen</li>
        <li>Agenda voor docentenvergaderingen en trainingen</li>
        <li>Contactgegevens van partnerbedrijven voor gastcolleges en bedrijfsbezoeken</li>
      </ul>
      <h3 class="text-xl font-semibold mb-2 text-purple-700">Waarom lesgeven bij ROC Nijmegen?</h3>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Innovatieve onderwijsomgeving met focus op praktijkgericht leren</li>
        <li>Ruimte voor persoonlijke en professionele groei</li>
        <li>Nauwe samenwerking met het bedrijfsleven</li>
        <li>Mogelijkheden om bij te dragen aan curriculumontwikkeling</li>
        <li>Een diverse en inclusieve werkomgeving</li>
      </ul>
    </div>

    <h2 class="text-2xl font-semibold mb-6 text-purple-800">Ons Docententeam</h2>
    <div v-if="loading" class="text-center">
      <p class="text-xl">Docenten laden...</p>
    </div>
    <div v-else-if="error" class="text-center text-red-600">
      <p>{{ error }}</p>
    </div>
    <div v-else-if="teachers.length === 0" class="text-center">
      <p>Er zijn momenteel geen docentenprofielen om weer te geven.</p>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div v-for="teacher in teachers" :key="teacher.id" class="bg-white shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-2 text-purple-700">{{ teacher.name }}</h2>
        <p class="text-gray-600 mb-2">Vakgebied: {{ teacher.subject }}</p>
        <p class="text-sm text-gray-500">Ervaring: {{ teacher.experience }} jaar</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/config'

export default {
  setup() {
    const teachers = ref([])
    const loading = ref(true)
    const error = ref('')

    onMounted(async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'teachers'))
        teachers.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (err) {
        console.error('Error fetching teachers:', err)
        error.value = 'Fout bij het laden van docenten. Probeer het later opnieuw.'
      } finally {
        loading.value = false
      }
    })

    return {
      teachers,
      loading,
      error
    }
  }
}
</script>