<template>
  <div class="editor-wrapper">
    <div class="editor-toolbar border rounded-t-lg p-2 bg-gray-50 flex flex-wrap gap-2">
      <button 
        v-for="format in formats" 
        :key="format.command"
        @click.prevent="format.action(editor)"
        :class="[
          'px-2 py-1 rounded hover:bg-gray-200',
          editor?.isActive(format.command, format.attrs) ? 'bg-gray-200' : ''
        ]"
        :title="format.label"
      >
        {{ format.label }}
      </button>
    </div>

    <editor-content 
      :editor="editor" 
      class="min-h-[300px] border-x border-b rounded-b-lg p-4 prose max-w-none"
    />
    
    
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, computed } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import { uploadJobImage } from '../utils/upload'
import { useAuthStore } from '../stores/auth'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue'])
const authStore = useAuthStore()

const formats = computed(() => [
  {
    label: 'B',
    command: 'bold',
    action: (editor) => editor.chain().focus().toggleBold().run()
  },
  {
    label: 'I',
    command: 'italic',
    action: (editor) => editor.chain().focus().toggleItalic().run()
  },
  {
    label: 'H1',
    command: 'heading',
    attrs: { level: 1 },
    action: (editor) => editor.chain().focus().toggleHeading({ level: 1 }).run()
  },
  {
    label: 'H2',
    command: 'heading',
    attrs: { level: 2 },
    action: (editor) => editor.chain().focus().toggleHeading({ level: 2 }).run()
  },
  {
    label: 'Quote',
    command: 'blockquote',
    action: (editor) => editor.chain().focus().toggleBlockquote().run()
  },
  {
    label: 'UL',
    command: 'bulletList',
    action: (editor) => editor.chain().focus().toggleBulletList().run()
  },
  {
    label: 'OL',
    command: 'orderedList',
    action: (editor) => editor.chain().focus().toggleOrderedList().run()
  },
  {
    label: 'Link',
    command: 'link',
    action: (editor) => {
      const url = window.prompt('Enter URL')
      if (url) {
        editor.chain().focus().setLink({ href: url }).run()
      }
    }
  }
])

const editor = useEditor({
  content: props.modelValue,
  extensions: [
    StarterKit,
    Image,
    Link.configure({
      openOnClick: false,
      HTMLAttributes: {
        class: 'text-blue-600 hover:text-blue-800 underline'
      }
    })
  ],
  onUpdate: ({ editor }) => {
    emit('update:modelValue', editor.getHTML())
  }
})

const handleImageUpload = async (event) => {
  const file = event.target.files[0]
  if (!file) return

  try {
    const url = await uploadJobImage(file, authStore.user.uid)
    editor.value.chain().focus().setImage({ src: url }).run()
  } catch (error) {
    console.error('Error uploading image:', error)
    alert('Failed to upload image. Please try again.')
  }
}

onBeforeUnmount(() => {
  editor.value?.destroy()
})
</script>

<style>
.ProseMirror {
  outline: none;
  min-height: 200px;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1 {
  font-size: 2em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.ProseMirror h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.ProseMirror blockquote {
  border-left: 3px solid #999;
  margin-left: 0;
  padding-left: 1rem;
  font-style: italic;
  color: #666;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
</style>