<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h1 class="text-3xl font-bold mb-6 text-purple-800">Contact</h1>
    
    <div class="bg-white shadow rounded-lg p-6">
      <p class="text-gray-700 mb-4">
        Heeft u vragen of opmerkingen? Neem gerust contact met ons op via onderstaand e-mailadres:
      </p>
      <a href="mailto:d.radic@roc-nijmegen.nl" class="text-xl text-blue-600 hover:text-blue-800">
        d.radic@roc-nijmegen.nl
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage'
}
</script>