<template>
  <div class="max-w-4xl mx-auto p-4">
    <nav class="text-sm breadcrumbs mb-4">
      <ul class="flex">
        <li><router-link to="/" class="text-blue-500 hover:text-blue-700">Home</router-link></li>
        <li class="mx-2">/</li>
        <li>Job Details</li>
      </ul>
    </nav>

    <div v-if="loading">Loading job details...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else-if="job" class="bg-white shadow-md rounded-lg p-6">
      <div class="mb-6 relative">
        <div class="flex space-x-4 overflow-x-auto pb-4">
          <img v-if="job.imageUrl" :src="job.imageUrl" alt="Company logo" class="w-full h-64 object-cover rounded">
          <div v-if="job.youtube_link" class="w-full h-64 flex-shrink-0">
            <iframe 
              :src="getYoutubeEmbedUrl(job.youtube_link)" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen
              class="w-full h-full rounded"
            ></iframe>
          </div>
        </div>
      </div>
      <h2 class="text-3xl font-bold mb-4">{{ job.title }}</h2>
      <p class="text-xl mb-2"><strong>Company:</strong> {{ job.company }}</p>
      <p class="mb-2"><strong>Location:</strong> {{ job.location }}</p>
      <p class="mb-2"><strong>Type:</strong> {{ job.type }}</p>
      <p class="mb-2"><strong>Experience:</strong> {{ job.experience }}</p>
      <p class="mb-2"><strong>Posted on:</strong> {{ formatDate(job.postedOn) }}</p>
      <p class="mb-2"><strong>Start Date:</strong> {{ formatDate(job.startDate) || 'Not specified' }}</p>
      <p class="mb-2"><strong>End Date:</strong> {{ formatDate(job.endDate) || 'Not specified' }}</p>
      <p class="mb-2"><strong>Categories:</strong> {{ job.categories ? job.categories.join(', ') : 'N/A' }}</p>
      <p class="mb-2"><strong>Skills:</strong> {{ job.skills ? job.skills.join(', ') : 'N/A' }}</p>
      
      <div class="mt-4">
        <h3 class="text-2xl font-semibold mb-2">About the Job</h3>
        <div class="prose max-w-none" v-html="sanitizedContent"></div>
      </div>

      <div class="mt-6 space-y-2">
        <h3 class="text-xl font-semibold mb-2">Contact Information</h3>
        <p v-if="job.website" class="mb-2">
          <strong>Website:</strong> 
          <a :href="job.website" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800">
            {{ job.website }}
          </a>
        </p>
        <p v-if="job.email" class="mb-2">
          <strong>Email:</strong> 
          <a :href="`mailto:${job.email}`" class="text-blue-600 hover:text-blue-800">
            {{ job.email }}
          </a>
        </p>
        <p v-if="job.phone" class="mb-2">
          <strong>Phone:</strong> 
          <a :href="`tel:${job.phone}`" class="text-blue-600 hover:text-blue-800">
            {{ job.phone }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { db } from '../firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import DOMPurify from 'dompurify'
import { getYoutubeEmbedUrl } from '../utils/youtube'

const route = useRoute()
const job = ref(null)
const loading = ref(true)
const error = ref('')

// Sanitize HTML content
const sanitizedContent = computed(() => {
  if (!job.value?.about) return ''
  return DOMPurify.sanitize(job.value.about, {
    ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'u', 'h1', 'h2', 'h3', 'h4', 'ul', 'ol', 'li', 'a', 'blockquote'],
    ALLOWED_ATTR: ['href', 'target', 'rel']
  })
})

onMounted(async () => {
  const jobId = route.params.id
  try {
    const jobDoc = await getDoc(doc(db, 'jobListings', jobId))
    if (jobDoc.exists()) {
      job.value = { id: jobDoc.id, ...jobDoc.data() }
    } else {
      error.value = 'Job not found'
    }
  } catch (err) {
    console.error('Error fetching job details:', err)
    error.value = 'Failed to load job details. Please try again later.'
  } finally {
    loading.value = false
  }
})

const formatDate = (timestamp) => {
  if (!timestamp) return 'N/A'
  if (timestamp.toDate) {
    const date = timestamp.toDate()
    return date.toLocaleDateString('nl-NL', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    })
  }
  return 'Invalid Date'
}
</script>

<style>
.prose {
  max-width: none;
}

.prose img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.prose a {
  color: #2563eb;
  text-decoration: underline;
}

.prose a:hover {
  color: #1d4ed8;
}

.prose blockquote {
  border-left: 4px solid #e5e7eb;
  padding-left: 1rem;
  color: #4b5563;
  font-style: italic;
}

.prose ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.prose ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
}
</style>