<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Successen', path: '/blog' },
      { name: post?.title || 'Loading...', path: `/blog/${$route.params.id}` }
    ]" />

    <div v-if="loading" class="text-center py-8">
      <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
      <p class="mt-2 text-gray-600">Laden...</p>
    </div>

    <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
      {{ error }}
    </div>

    <article v-else class="bg-white rounded-lg shadow-md overflow-hidden">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
        <!-- Cover Image -->
        <img
          v-if="post.coverImage"
          :src="post.coverImage"
          :alt="post.title"
          class="w-full h-64 object-cover rounded"
        />
        
        <!-- YouTube Video Thumbnail -->
        <a 
          v-if="youtubeVideoId"
          :href="youtubeWatchUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="relative block w-full h-64 group"
        >
          <img 
            :src="youtubeThumbnailUrl"
            :alt="'YouTube video thumbnail'"
            class="w-full h-full object-cover rounded"
          />
          <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 group-hover:bg-opacity-60 transition-opacity">
            <svg class="w-16 h-16 text-white" viewBox="0 0 24 24" fill="currentColor">
              <path d="M8 5v14l11-7z"/>
            </svg>
          </div>
        </a>
      </div>
      
      <div class="p-8">
        <div class="flex justify-between items-start mb-4">
          <h1 class="text-4xl font-bold">{{ post.title }}</h1>
          <router-link
            v-if="isTeacher"
            :to="`/blog/edit/${post.id}`"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
          >
            Bewerken
          </router-link>
        </div>
        
        <div class="flex items-center text-gray-600 mb-8">
          <span>{{ formatDate(post.createdAt) }}</span>
          <span class="mx-2">•</span>
          <span>Door {{ post.authorEmail }}</span>
          <span v-if="post.updatedAt && post.updatedAt !== post.createdAt" class="ml-2 text-sm text-gray-500">
            (Bijgewerkt: {{ formatDate(post.updatedAt) }})
          </span>
        </div>

        <div class="prose max-w-none" v-html="post.content"></div>
      </div>
    </article>

    <div class="mt-8">
      <router-link
        to="/blog"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200"
      >
        ← Terug naar Successen
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { db } from '../firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import Breadcrumb from '../components/Breadcrumb.vue'
import { useAuthStore } from '../stores/auth'
import { getYoutubeVideoId, getYoutubeThumbnailUrl, getYoutubeWatchUrl } from '../utils/youtube'

const route = useRoute()
const authStore = useAuthStore()
const isTeacher = computed(() => authStore.isTeacher)

const post = ref(null)
const loading = ref(true)
const error = ref(null)

const youtubeVideoId = computed(() => getYoutubeVideoId(post.value?.youtubeUrl))
const youtubeThumbnailUrl = computed(() => getYoutubeThumbnailUrl(youtubeVideoId.value))
const youtubeWatchUrl = computed(() => getYoutubeWatchUrl(youtubeVideoId.value))

onMounted(async () => {
  try {
    const docRef = doc(db, 'blogPosts', route.params.id)
    const docSnap = await getDoc(docRef)
    
    if (docSnap.exists()) {
      post.value = {
        id: docSnap.id,
        ...docSnap.data()
      }
    } else {
      error.value = 'Post niet gevonden'
    }
  } catch (err) {
    console.error('Error fetching blog post:', err)
    error.value = 'Fout bij het laden van het bericht. Probeer het later opnieuw.'
  } finally {
    loading.value = false
  }
})

const formatDate = (timestamp) => {
  if (!timestamp) return 'N/A'
  if (timestamp.toDate) {
    const date = timestamp.toDate()
    return date.toLocaleDateString('nl-NL', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    })
  }
  return 'Invalid Date'
}
</script>

<style>
.group:hover .group-hover\:bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
</style>