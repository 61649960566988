<template>
  <div class="min-h-screen bg-gray-100">
    <!-- Top banner -->
    <div class="bg-blue-200 py-2 text-center">
      <p class="text-purple-800">
        Leren door te doen, groeien door te ervaren.
      </p>
    </div>

    <!-- Navigation -->
    <nav class="bg-white shadow-sm">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex items-center">
            <router-link to="/" class="flex-shrink-0 flex items-center">
              <img src="https://www.roc-nijmegen.nl/templates/perfecttemplate/images/logo-roc-nijmegen.svg?1701158368" alt="ROC Nijmegen Logo" class="h-[33px] w-auto">
            </router-link>
          </div>
          <div class="hidden md:flex items-center space-x-4">
            <router-link to="/" class="text-gray-700 hover:text-purple-800">Projecten</router-link>
            <router-link to="/blog" class="text-gray-700 hover:text-purple-800">Successen</router-link>
            
            <!-- Info Dropdown -->
            <div class="relative" v-click-outside="closeInfoMenu">
              <button 
                @click="toggleInfoMenu"
                class="text-gray-700 hover:text-purple-800 flex items-center"
              >
                Info
                <svg 
                  class="ml-1 h-4 w-4" 
                  :class="{ 'transform rotate-180': isInfoMenuOpen }"
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              <!-- Info Dropdown Menu -->
              <div 
                v-show="isInfoMenuOpen"
                class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div class="py-1">
                  <router-link 
                    to="/bedrijven" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Bedrijven
                  </router-link>
                  <router-link 
                    to="/students" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Students
                  </router-link>
                  <router-link 
                    to="/teachers" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Teachers
                  </router-link>
                  <router-link 
                    to="/contact" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Contact
                  </router-link>
                  <router-link 
                    to="/over-ons" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Over ons
                  </router-link>
                  <div class="border-t border-gray-100 my-1"></div>
                  <router-link 
                    to="/privacy" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Privacybeleid
                  </router-link>
                  <router-link 
                    to="/terms" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isInfoMenuOpen = false"
                  >
                    Algemene Voorwaarden
                  </router-link>
                </div>
              </div>
            </div>
            
            <!-- Login/Mijn ROC Button with Dropdown -->
            <div class="relative" v-click-outside="closeUserMenu">
              <button 
                v-if="user"
                @click="toggleUserMenu"
                class="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 flex items-center"
              >
                Mijn ROC
                <svg 
                  class="ml-2 h-4 w-4" 
                  :class="{ 'transform rotate-180': isUserMenuOpen }"
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              
              <!-- Login Button for non-authenticated users -->
              <router-link 
                v-else
                to="/login" 
                class="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700"
              >
                Login
              </router-link>
              
              <!-- Dropdown Menu -->
              <div 
                v-show="isUserMenuOpen"
                class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div class="py-1">
                  <router-link 
                    to="/dashboard" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isUserMenuOpen = false"
                  >
                    Dashboard
                  </router-link>
                  <button 
                    @click="handleLogout"
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Uitloggen
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          <div class="md:hidden flex items-center">
            <button @click="toggleMenu" class="text-gray-700 hover:text-purple-800 focus:outline-none">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path v-if="!isMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      
      <!-- Mobile menu -->
      <div v-show="isMenuOpen" class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <router-link to="/" class="block text-gray-700 hover:text-purple-800 py-2">Projecten</router-link>
          <router-link to="/blog" class="block text-gray-700 hover:text-purple-800 py-2">Successen</router-link>
          <router-link to="/bedrijven" class="block text-gray-700 hover:text-purple-800 py-2">Bedrijven</router-link>
          <router-link to="/students" class="block text-gray-700 hover:text-purple-800 py-2">Students</router-link>
          <router-link to="/teachers" class="block text-gray-700 hover:text-purple-800 py-2">Teachers</router-link>
          <router-link to="/contact" class="block text-gray-700 hover:text-purple-800 py-2">Contact</router-link>
          <router-link to="/over-ons" class="block text-gray-700 hover:text-purple-800 py-2">Over ons</router-link>
          <router-link to="/privacy" class="block text-gray-700 hover:text-purple-800 py-2">Privacybeleid</router-link>
          <router-link to="/terms" class="block text-gray-700 hover:text-purple-800 py-2">Algemene Voorwaarden</router-link>
          
          <!-- Mobile Login/Dashboard -->
          <template v-if="user">
            <router-link to="/dashboard" class="block text-gray-700 hover:text-purple-800 py-2">Dashboard</router-link>
            <button 
              @click="handleLogout"
              class="block w-full text-left text-gray-700 hover:text-purple-800 py-2"
            >
              Uitloggen
            </button>
          </template>
          <router-link 
            v-else
            to="/login" 
            class="block text-left bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 mt-2"
          >
            Login
          </router-link>
        </div>
      </div>
    </nav>

    <!-- Main content -->
    <main class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <router-view></router-view>
    </main>

    <!-- Cookie Consent -->
    <CookieConsent />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useAuthStore } from './stores/auth'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import CookieConsent from './components/CookieConsent.vue'

const router = useRouter()
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const isMenuOpen = ref(false)
const isUserMenuOpen = ref(false)
const isInfoMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const toggleUserMenu = () => {
  isUserMenuOpen.value = !isUserMenuOpen.value
}

const toggleInfoMenu = () => {
  isInfoMenuOpen.value = !isInfoMenuOpen.value
}

const closeUserMenu = () => {
  isUserMenuOpen.value = false
}

const closeInfoMenu = () => {
  isInfoMenuOpen.value = false
}

const handleLogout = async () => {
  try {
    await authStore.logout()
    isUserMenuOpen.value = false
    isMenuOpen.value = false
    router.push('/login')
  } catch (error) {
    console.error('Logout error:', error)
  }
}

// Click outside directive
const vClickOutside = {
  mounted(el, binding) {
    el._clickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el._clickOutside)
  },
  unmounted(el) {
    document.removeEventListener('click', el._clickOutside)
  }
}
</script>