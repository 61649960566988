<template>
  <div class="max-w-4xl mx-auto px-4">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Job Approval', path: '/teacher-approval' }
    ]" />

    <!-- Tabs -->
    <div class="mb-6 border-b border-gray-200">
      <nav class="-mb-px flex space-x-8">
        <button
          @click="activeTab = 'pending'"
          :class="[
            activeTab === 'pending'
              ? 'border-purple-500 text-purple-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
          ]"
        >
          Pending Approval
        </button>
        <button
          @click="activeTab = 'approved'"
          :class="[
            activeTab === 'approved'
              ? 'border-purple-500 text-purple-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
          ]"
        >
          Approved Jobs
        </button>
      </nav>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="text-center py-8">
      <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
      <p class="mt-2 text-gray-600">Loading job listings...</p>
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
      <p class="font-bold">Error</p>
      <p>{{ error }}</p>
    </div>

    <!-- Pending Jobs -->
    <div v-else-if="activeTab === 'pending'" class="space-y-6">
      <h2 class="text-2xl font-bold text-gray-900">Jobs Pending Approval</h2>
      <div v-if="pendingJobs.length === 0" class="text-center py-8 bg-gray-50 rounded-lg">
        <p class="text-gray-500">No jobs pending approval</p>
      </div>
      <div v-else class="space-y-4">
        <JobCard
          v-for="job in pendingJobs"
          :key="job.id"
          :job="job"
          :showActions="true"
          @approve="approveJob"
          @reject="rejectJob"
          @addKerntaken="openKerntakenModal"
        />
      </div>
    </div>

    <!-- Approved Jobs -->
    <div v-else class="space-y-6">
      <h2 class="text-2xl font-bold text-gray-900">Approved Jobs</h2>
      <div v-if="approvedJobs.length === 0" class="text-center py-8 bg-gray-50 rounded-lg">
        <p class="text-gray-500">No approved jobs found</p>
      </div>
      <div v-else class="space-y-4">
        <JobCard
          v-for="job in approvedJobs"
          :key="job.id"
          :job="job"
          :showActions="false"
          @addKerntaken="openKerntakenModal"
        />
      </div>
    </div>

    <!-- Kerntaken Modal -->
    <KerntakenModal
      v-if="showKerntakenModal"
      :job="selectedJob"
      @close="closeKerntakenModal"
      @save="saveKerntaken"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { db } from '../firebase/config'
import { collection, query, where, orderBy, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore'
import Breadcrumb from '../components/Breadcrumb.vue'
import JobCard from '../components/JobCard.vue'
import KerntakenModal from '../components/KerntakenModal.vue'

export default {
  name: 'TeacherApproval',
  
  components: {
    Breadcrumb,
    JobCard,
    KerntakenModal
  },

  setup() {
    const activeTab = ref('pending')
    const pendingJobs = ref([])
    const approvedJobs = ref([])
    const loading = ref(true)
    const error = ref('')
    const showKerntakenModal = ref(false)
    const selectedJob = ref(null)

    const fetchJobs = async () => {
      try {
        loading.value = true
        error.value = ''

        // Create the job listings collection if it doesn't exist
        const jobsCollection = collection(db, 'jobListings')
        
        // Fetch pending jobs
        const pendingQuery = query(
          jobsCollection,
          where('approved', '==', false),
          orderBy('postedOn', 'desc')
        )
        
        const pendingSnapshot = await getDocs(pendingQuery).catch(err => {
          if (err.code === 'failed-precondition') {
            // Create composite index if it doesn't exist
            throw new Error('Please create the required index for the jobListings collection. Contact the administrator.')
          }
          throw err
        })
        
        pendingJobs.value = pendingSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        // Fetch approved jobs
        const approvedQuery = query(
          jobsCollection,
          where('approved', '==', true),
          orderBy('postedOn', 'desc')
        )
        
        const approvedSnapshot = await getDocs(approvedQuery)
        approvedJobs.value = approvedSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (err) {
        console.error('Error fetching jobs:', err)
        error.value = err.message || 'Failed to load job listings. Please try again later.'
      } finally {
        loading.value = false
      }
    }

    const approveJob = async (jobId) => {
      try {
        await updateDoc(doc(db, 'jobListings', jobId), { 
          approved: true,
          approvedAt: new Date()
        })
        await fetchJobs()
      } catch (err) {
        console.error('Error approving job:', err)
        error.value = 'Failed to approve job. Please try again.'
      }
    }

    const rejectJob = async (jobId) => {
      if (confirm('Are you sure you want to reject this job? This action cannot be undone.')) {
        try {
          await deleteDoc(doc(db, 'jobListings', jobId))
          await fetchJobs()
        } catch (err) {
          console.error('Error rejecting job:', err)
          error.value = 'Failed to reject job. Please try again.'
        }
      }
    }

    const openKerntakenModal = (job) => {
      selectedJob.value = job
      showKerntakenModal.value = true
    }

    const closeKerntakenModal = () => {
      selectedJob.value = null
      showKerntakenModal.value = false
    }

    const saveKerntaken = async (jobId, { kerntaken, feedback }) => {
      try {
        await updateDoc(doc(db, 'jobListings', jobId), { 
          kerntaken,
          feedback,
          updatedAt: new Date()
        })
        await fetchJobs()
        closeKerntakenModal()
      } catch (err) {
        console.error('Error saving kerntaken:', err)
        error.value = 'Failed to save kerntaken. Please try again.'
      }
    }

    onMounted(fetchJobs)

    return {
      activeTab,
      pendingJobs,
      approvedJobs,
      loading,
      error,
      showKerntakenModal,
      selectedJob,
      approveJob,
      rejectJob,
      openKerntakenModal,
      closeKerntakenModal,
      saveKerntaken
    }
  }
}
</script>