<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-6">Algemene Voorwaarden</h1>
    
    <div class="prose max-w-none">
      <p class="mb-4">Laatst bijgewerkt: {{ new Date().toLocaleDateString('nl-NL') }}</p>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">1. Acceptatie van Voorwaarden</h2>
        <p>
          Door gebruik te maken van deze website gaat u akkoord met deze algemene voorwaarden.
          Als u niet akkoord gaat met deze voorwaarden, gebruik dan deze website niet.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">2. Aansprakelijkheid</h2>
        <p>
          We streven ernaar om accurate en actuele informatie te verstrekken, maar bieden geen garanties.
          We zijn niet aansprakelijk voor:
        </p>
        <ul class="list-disc ml-6 mt-2">
          <li>Directe of indirecte schade voortvloeiend uit het gebruik van deze website</li>
          <li>Onjuistheden of onvolledigheden in de inhoud</li>
          <li>Technische problemen of storingen</li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">3. Intellectueel Eigendom</h2>
        <p>
          Alle content op deze website is eigendom van ROC Nijmegen of onze partners.
          Het is niet toegestaan om content te kopiëren of te verspreiden zonder toestemming.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">4. Gebruiksvoorwaarden</h2>
        <p>
          U stemt ermee in om:
        </p>
        <ul class="list-disc ml-6 mt-2">
          <li>De website alleen te gebruiken voor legitieme doeleinden</li>
          <li>Geen schadelijke software te verspreiden</li>
          <li>Geen inbreuk te maken op rechten van anderen</li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">5. Wijzigingen</h2>
        <p>
          We behouden ons het recht voor om deze voorwaarden op elk moment te wijzigen.
          Blijvend gebruik van de website na wijzigingen betekent acceptatie van de nieuwe voorwaarden.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">6. Toepasselijk Recht</h2>
        <p>
          Deze voorwaarden vallen onder Nederlands recht. Eventuele geschillen worden voorgelegd aan de bevoegde rechter in Nederland.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">7. Contact</h2>
        <p>
          Voor vragen over deze voorwaarden kunt u contact opnemen via:
          <a href="mailto:d.radic@roc-nijmegen.nl" class="text-purple-600 hover:text-purple-800">d.radic@roc-nijmegen.nl</a>
        </p>
      </section>
    </div>
  </div>
</template>