import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Dashboard from '../views/Dashboard.vue'
import PostJob from '../views/PostJob.vue'
import CompanyJobListings from '../views/CompanyJobListings.vue'
import TeacherApproval from '../views/TeacherApproval.vue'
import JobDetails from '../views/JobDetails.vue'
import EditJob from '../views/EditJob.vue'
import BedrijvenPage from '../views/BedrijvenPage.vue'
import StudentPage from '../views/StudentPage.vue'
import TeacherPage from '../views/TeacherPage.vue'
import ContactPage from '../views/ContactPage.vue'
import OverOnsPage from '../views/OverOnsPage.vue'
import BlogList from '../views/BlogList.vue'
import BlogPost from '../views/BlogPost.vue'
import CreateBlogPost from '../views/CreateBlogPost.vue'
import EditBlogPost from '../views/EditBlogPost.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import { useAuthStore } from '../stores/auth'

const routes = [
  { path: '/', component: Home },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/dashboard', component: Dashboard, meta: { requiresAuth: true } },
  { path: '/post-job', component: PostJob, meta: { requiresAuth: true, requiresCompany: true } },
  { path: '/company-job-listings', component: CompanyJobListings, meta: { requiresAuth: true, requiresCompany: true } },
  { path: '/teacher-approval', component: TeacherApproval, meta: { requiresAuth: true, requiresTeacher: true, requiresApproval: true } },
  { path: '/job/:id', name: 'JobDetails', component: JobDetails },
  { path: '/edit-job/:id', name: 'EditJob', component: EditJob, meta: { requiresAuth: true, requiresCompany: true } },
  { path: '/bedrijven', component: BedrijvenPage },
  { path: '/students', component: StudentPage },
  { path: '/teachers', component: TeacherPage },
  { path: '/contact', component: ContactPage },
  { path: '/over-ons', component: OverOnsPage },
  { path: '/blog', component: BlogList },
  { path: '/blog/:id', component: BlogPost },
  { path: '/blog/create', component: CreateBlogPost, meta: { requiresAuth: true, requiresTeacher: true } },
  { path: '/blog/edit/:id', component: EditBlogPost, meta: { requiresAuth: true, requiresTeacher: true } },
  { path: '/privacy', component: PrivacyPolicy },
  { path: '/terms', component: TermsAndConditions },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (to.meta.requiresAuth && !authStore.user) {
    next('/login')
    return
  }

  if (to.meta.requiresCompany && authStore.userRole !== 'company') {
    next('/dashboard')
    return
  }

  if (to.meta.requiresTeacher && !authStore.isTeacher) {
    next('/dashboard')
    return
  }

  if (to.meta.requiresApproval && !authStore.isApprovedTeacher) {
    next('/dashboard')
    return
  }

  next()
})

export default router