<template>
  <nav class="text-sm breadcrumbs mb-4">
    <ul class="flex">
      <li v-for="(crumb, index) in crumbs" :key="index">
        <template v-if="index < crumbs.length - 1">
          <router-link :to="crumb.path" class="text-blue-500 hover:text-blue-700">{{ crumb.name }}</router-link>
          <span class="mx-2">/</span>
        </template>
        <template v-else>
          <span class="text-gray-500">{{ crumb.name }}</span>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
      required: true
    }
  }
}
</script>