<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h1 class="text-3xl font-bold mb-6 text-purple-800">Studenten</h1>
    
    <div class="bg-white shadow rounded-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Voor Studenten</h2>
      <p class="text-gray-700 mb-4">
        Welkom bij het ROC Nijmegen studentenportaal. Hier vind je alle informatie die je nodig hebt voor een succesvolle studieperiode en een goede start van je carrière.
      </p>
      <h3 class="text-xl font-semibold mb-2 text-purple-700">Wat kun je hier vinden?</h3>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Actuele stageopdrachten en projecten van bedrijven</li>
        <li>Informatie over beschikbare opleidingen en specialisaties</li>
        <li>Toegang tot online leermateriaal en resources</li>
        <li>Agenda voor workshops, gastcolleges en carrière-evenementen</li>
        <li>Mogelijkheden voor persoonlijke begeleiding en coaching</li>
      </ul>
      <h3 class="text-xl font-semibold mb-2 text-purple-700">Waarom kiezen voor ROC Nijmegen?</h3>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Praktijkgericht onderwijs met nauwe banden met het bedrijfsleven</li>
        <li>Moderne faciliteiten en up-to-date lesmethoden</li>
        <li>Persoonlijke aandacht en begeleiding</li>
        <li>Uitstekende doorstroommogelijkheden naar vervolgopleidingen of de arbeidsmarkt</li>
        <li>Een breed netwerk van partnerbedrijven voor stages en baankansen</li>
      </ul>
    </div>

    <h2 class="text-2xl font-semibold mb-6 text-purple-800">Onze Studenten</h2>
    <div v-if="loading" class="text-center">
      <p class="text-xl">Studenten laden...</p>
    </div>
    <div v-else-if="error" class="text-center text-red-600">
      <p>{{ error }}</p>
    </div>
    <div v-else-if="students.length === 0" class="text-center">
      <p>Er zijn momenteel geen studentenprofielen om weer te geven.</p>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div v-for="student in students" :key="student.id" class="bg-white shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-2 text-purple-700">{{ student.name }}</h2>
        <p class="text-gray-600 mb-2">Opleiding: {{ student.course }}</p>
        <p class="text-sm text-gray-500">Jaar: {{ student.year }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/config'

export default {
  setup() {
    const students = ref([])
    const loading = ref(true)
    const error = ref('')

    onMounted(async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'students'))
        students.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (err) {
        console.error('Error fetching students:', err)
        error.value = 'Fout bij het laden van studenten. Probeer het later opnieuw.'
      } finally {
        loading.value = false
      }
    })

    return {
      students,
      loading,
      error
    }
  }
}
</script>