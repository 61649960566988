<template>
  <div class="max-w-4xl mx-auto">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Post Job', path: '/post-job' }
    ]" />
    
    <h2 class="text-2xl font-bold mb-4">Post a New Job</h2>
    <form @submit.prevent="handleSubmit" class="space-y-4">
      <div>
        <label for="title" class="block text-sm font-medium text-gray-700">Job Title</label>
        <input type="text" id="title" v-model="jobData.title" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>
      
      <div>
        <label for="company" class="block text-sm font-medium text-gray-700">Company Name</label>
        <input type="text" id="company" v-model="jobData.company" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>
      
      <div>
        <label for="type" class="block text-sm font-medium text-gray-700">Job Type</label>
        <select id="type" v-model="jobData.type" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          <option value="Full-time">Full-time</option>
          <option value="Part-time">Part-time</option>
          <option value="Contract">Contract</option>
          <option value="Internship">Internship</option>
          <option value="project">Project</option>
        </select>
      </div>
      
      <div>
        <label for="experience" class="block text-sm font-medium text-gray-700">Experience Level</label>
        <select id="experience" v-model="jobData.experience" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          <option value="Entry Level">Entry Level</option>
          <option value="Mid Level">Mid Level</option>
          <option value="Senior Level">Senior Level</option>
        </select>
      </div>
      
      <div>
        <label for="location" class="block text-sm font-medium text-gray-700">Location</label>
        <input type="text" id="location" v-model="jobData.location" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label for="startDate" class="block text-sm font-medium text-gray-700">Start Date</label>
          <input type="date" id="startDate" v-model="jobData.startDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
        </div>
        
        <div>
          <label for="endDate" class="block text-sm font-medium text-gray-700">End Date</label>
          <input type="date" id="endDate" v-model="jobData.endDate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
        </div>
      </div>

      <div>
        <label class="block text-sm font-medium text-gray-700">Status</label>
        <div class="mt-2">
          <label class="inline-flex items-center">
            <input type="radio" v-model="jobData.isActive" :value="true" class="form-radio">
            <span class="ml-2">Active</span>
          </label>
          <label class="inline-flex items-center ml-6">
            <input type="radio" v-model="jobData.isActive" :value="false" class="form-radio">
            <span class="ml-2">Inactive</span>
          </label>
        </div>
      </div>
      
      <div>
        <label for="categories" class="block text-sm font-medium text-gray-700">Categories</label>
        <div class="mt-2 space-y-2">
          <div v-for="category in availableCategories" :key="category">
            <label class="inline-flex items-center">
              <input type="checkbox" v-model="jobData.categories" :value="category" class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <span class="ml-2">{{ category }}</span>
            </label>
          </div>
          <div>
            <label class="inline-flex items-center">
              <input type="checkbox" v-model="showCustomCategory" class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <span class="ml-2">Other</span>
            </label>
          </div>
          <div v-if="showCustomCategory">
            <input type="text" v-model="customCategory" placeholder="Enter custom category" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          </div>
        </div>
      </div>
      
      <div>
        <label for="skills" class="block text-sm font-medium text-gray-700">Skills (comma-separated)</label>
        <input type="text" id="skills" v-model="skillsInput" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>
      
      <div>
        <label for="about" class="block text-sm font-medium text-gray-700">About the Job</label>
        <JobEditor v-model="jobData.about" />
      </div>

      <div>
        <label for="website" class="block text-sm font-medium text-gray-700">Website</label>
        <input type="url" id="website" v-model="jobData.website" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>

      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
        <input type="email" id="email" v-model="jobData.email" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>

      <div>
        <label for="phone" class="block text-sm font-medium text-gray-700">Phone Number</label>
        <input type="tel" id="phone" v-model="jobData.phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>

      <div>
        <label for="companyLogo" class="block text-sm font-medium text-gray-700">Company Logo</label>
        <input type="file" id="companyLogo" @change="handleFileUpload" accept="image/*" class="mt-1 block w-full">
        <img v-if="previewUrl" :src="previewUrl" alt="Logo preview" class="mt-2 h-32 object-contain">
      </div>

      <div>
        <label for="youtube_link" class="block text-sm font-medium text-gray-700">YouTube Video URL</label>
        <input type="url" id="youtube_link" v-model="jobData.youtube_link" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
      </div>
      
      <div>
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" :disabled="isSubmitting">
          {{ isSubmitting ? 'Posting...' : 'Post Job' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { db } from '../firebase/config'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { uploadCompanyLogo } from '../utils/upload'
import Breadcrumb from '../components/Breadcrumb.vue'
import JobEditor from '../components/JobEditor.vue'

const router = useRouter()
const authStore = useAuthStore()
const isSubmitting = ref(false)
const logoFile = ref(null)
const previewUrl = ref('')
const showCustomCategory = ref(false)
const customCategory = ref('')
const skillsInput = ref('')

const jobData = reactive({
  title: '',
  company: '',
  type: 'Full-time',
  experience: 'Entry Level',
  location: '',
  categories: [],
  about: '',
  website: '',
  email: '',
  phone: '',
  youtube_link: '',
  imageUrl: '',
  startDate: '',
  endDate: '',
  isActive: true
})

const availableCategories = [
  'Web Development',
  'Mobile Development',
  'Data Science',
  'Machine Learning',
  'DevOps',
  'UI/UX Design',
  'Elektro',
  'Metaalbewerking',
  'Autotechniek'
]

const handleFileUpload = (event) => {
  const file = event.target.files[0]
  if (file) {
    logoFile.value = file
    previewUrl.value = URL.createObjectURL(file)
  }
}

const handleSubmit = async () => {
  try {
    isSubmitting.value = true

    let imageUrl = null
    if (logoFile.value) {
      imageUrl = await uploadCompanyLogo(logoFile.value, authStore.user.uid)
    }

    const finalCategories = [...jobData.categories]
    if (showCustomCategory.value && customCategory.value) {
      finalCategories.push(customCategory.value)
    }

    const jobDoc = {
      ...jobData,
      categories: finalCategories,
      skills: skillsInput.value.split(',').map(skill => skill.trim()),
      imageUrl,
      companyId: authStore.user.uid,
      postedOn: serverTimestamp(),
      startDate: jobData.startDate ? new Date(jobData.startDate) : null,
      endDate: jobData.endDate ? new Date(jobData.endDate) : null,
      approved: false
    }

    await addDoc(collection(db, 'jobListings'), jobDoc)
    router.push('/company-job-listings')
  } catch (error) {
    console.error('Error posting job:', error)
    alert('Failed to post job. Please try again.')
  } finally {
    isSubmitting.value = false
  }
}
</script>