import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../firebase/config'

export async function uploadCompanyLogo(file, userId) {
  if (!file) return null

  try {
    const fileRef = storageRef(storage, `companyLogos/${userId}/${Date.now()}_${file.name}`)
    await uploadBytes(fileRef, file)
    return getDownloadURL(fileRef)
  } catch (error) {
    console.error('Error uploading company logo:', error)
    throw error
  }
}

export async function uploadJobImage(file, userId) {
  if (!file) return null

  try {
    const fileRef = storageRef(storage, `companyLogos/${userId}/${Date.now()}_${file.name}`)
    await uploadBytes(fileRef, file)
    return getDownloadURL(fileRef)
  } catch (error) {
    console.error('Error uploading job image:', error)
    throw error
  }
}

export async function uploadBlogImage(file) {
  if (!file) return null

  try {
    const fileRef = storageRef(storage, `blog-images/${Date.now()}_${file.name}`)
    await uploadBytes(fileRef, file)
    return getDownloadURL(fileRef)
  } catch (error) {
    console.error('Error uploading blog image:', error)
    throw error
  }
}

export async function uploadBlogCover(file) {
  if (!file) return null

  try {
    const fileRef = storageRef(storage, `blog-covers/${Date.now()}_${file.name}`)
    await uploadBytes(fileRef, file)
    return getDownloadURL(fileRef)
  } catch (error) {
    console.error('Error uploading blog cover:', error)
    throw error
  }
}