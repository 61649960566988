<template>
  <div class="max-w-4xl mx-auto">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Your Job Listings', path: '/company-job-listings' }
    ]" />
    
    <h2 class="text-2xl font-bold mb-4">Your Job Listings</h2>
    <div v-if="loading">Loading job listings...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else-if="jobListings.length === 0">
      <p>You haven't posted any jobs yet.</p>
      <router-link to="/post-job" class="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Post Your First Job
      </router-link>
    </div>
    <div v-else>
      <div v-for="job in jobListings" :key="job.id" class="bg-white shadow-md rounded-lg p-4 mb-4">
        <h3 class="text-xl font-semibold mb-2">{{ job.title }}</h3>
        <p class="mb-1"><strong>Company:</strong> {{ job.company }}</p>
        <p class="mb-1"><strong>Type:</strong> {{ job.type }}</p>
        <p class="mb-1"><strong>Experience:</strong> {{ job.experience }}</p>
        <p class="mb-1"><strong>Location:</strong> {{ job.location }}</p>
        <p class="mb-1"><strong>Posted on:</strong> {{ formatDate(job.postedOn) }}</p>
        <p class="mb-1"><strong>Status:</strong> <span :class="job.approved ? 'text-green-600' : 'text-yellow-600'">{{ job.approved ? 'Approved' : 'Pending Approval' }}</span></p>
        <div class="mt-4 flex space-x-2">
          <router-link :to="{ name: 'EditJob', params: { id: job.id } }" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Edit
          </router-link>
          <button @click="deleteJob(job.id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { db } from '../firebase/config'
import { collection, query, where, orderBy, getDocs, doc, deleteDoc } from 'firebase/firestore'
import Breadcrumb from '../components/Breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const jobListings = ref([])
    const loading = ref(true)
    const error = ref('')

    const fetchJobListings = async () => {
      try {
        if (!authStore.user) {
          throw new Error('User not authenticated')
        }

        const q = query(
          collection(db, 'jobListings'),
          where('companyId', '==', authStore.user.uid),
          orderBy('postedOn', 'desc')
        )

        const querySnapshot = await getDocs(q)
        jobListings.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (err) {
        console.error('Error fetching job listings:', err)
        error.value = 'Failed to load job listings. Please try again later.'
      } finally {
        loading.value = false
      }
    }

    const deleteJob = async (jobId) => {
      if (confirm('Are you sure you want to delete this job listing?')) {
        try {
          await deleteDoc(doc(db, 'jobListings', jobId))
          jobListings.value = jobListings.value.filter(job => job.id !== jobId)
        } catch (err) {
          console.error('Error deleting job:', err)
          alert('Failed to delete job. Please try again.')
        }
      }
    }

    const formatDate = (timestamp) => {
      if (!timestamp) return 'N/A'
      if (timestamp.toDate) {
        const date = timestamp.toDate()
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      }
      return 'Invalid Date'
    }

    onMounted(fetchJobListings)

    return {
      jobListings,
      loading,
      error,
      deleteJob,
      formatDate
    }
  }
}
</script>