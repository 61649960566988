<template>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
    <div class="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
      <div class="p-6">
        <div class="flex justify-between items-center mb-6">
          <h3 class="text-xl font-semibold">{{ job.title }} - Kerntaken</h3>
          <button @click="$emit('close')" class="text-gray-400 hover:text-gray-500">
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="space-y-6">
          <div v-for="kerntaak in softwareDevelopmentKerntaken.kerntaken" :key="kerntaak.id"
            class="border rounded-lg p-4">
            <div class="flex items-start gap-3">
              <input
                type="checkbox"
                :id="kerntaak.id"
                v-model="selectedKerntaken"
                :value="kerntaak"
                class="mt-1"
              >
              <div>
                <label :for="kerntaak.id" class="font-medium">{{ kerntaak.title }}</label>
                <p class="text-sm text-gray-600">{{ kerntaak.description }}</p>
                
                <div class="mt-3 space-y-2">
                  <div>
                    <h4 class="text-sm font-medium">Complexiteit:</h4>
                    <p class="text-sm text-gray-600">{{ kerntaak.subItems.complexiteit }}</p>
                  </div>
                  <div>
                    <h4 class="text-sm font-medium">Verantwoordelijkheid:</h4>
                    <p class="text-sm text-gray-600">{{ kerntaak.subItems.verantwoordelijkheid }}</p>
                  </div>
                  <div>
                    <h4 class="text-sm font-medium">Vakkennis en vaardigheden:</h4>
                    <ul class="list-disc list-inside text-sm text-gray-600">
                      <li v-for="(item, index) in kerntaak.subItems.vakkennis" :key="index">
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label class="block font-medium mb-2">Feedback voor student:</label>
            <textarea
              v-model="feedback"
              rows="4"
              class="w-full border rounded-lg p-2"
              placeholder="Voeg hier feedback toe..."
            ></textarea>
          </div>

          <div class="flex justify-end gap-4">
            <button @click="$emit('close')"
              class="px-4 py-2 border rounded hover:bg-gray-50">
              Cancel
            </button>
            <button @click="save"
              class="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { softwareDevelopmentKerntaken } from '../data/softwareDevelopmentQualifications'

export default {
  name: 'KerntakenModal',
  
  props: {
    job: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const selectedKerntaken = ref(props.job.kerntaken || [])
    const feedback = ref(props.job.feedback || '')

    const save = () => {
      emit('save', props.job.id, {
        kerntaken: selectedKerntaken.value,
        feedback: feedback.value
      })
    }

    return {
      softwareDevelopmentKerntaken,
      selectedKerntaken,
      feedback,
      save
    }
  }
}
</script>