<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-6">Privacybeleid</h1>
    
    <div class="prose max-w-none">
      <p class="mb-4">Laatst bijgewerkt: {{ new Date().toLocaleDateString('nl-NL') }}</p>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">1. Algemeen</h2>
        <p>
          Dit privacybeleid beschrijft hoe wij uw persoonlijke gegevens verzamelen, gebruiken en beschermen wanneer u onze website gebruikt.
          Door gebruik te maken van deze website stemt u in met dit privacybeleid.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">2. Verzamelde Informatie</h2>
        <p>
          We verzamelen alleen informatie die noodzakelijk is voor het functioneren van de website en het leveren van onze diensten.
          Dit kan het volgende omvatten:
        </p>
        <ul class="list-disc ml-6 mt-2">
          <li>E-mailadres (voor inloggen en communicatie)</li>
          <li>Gebruikersnaam</li>
          <li>Door u geplaatste content</li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">3. Gebruik van Informatie</h2>
        <p>
          We gebruiken de verzamelde informatie uitsluitend voor:
        </p>
        <ul class="list-disc ml-6 mt-2">
          <li>Het beheren van uw account</li>
          <li>Het verbeteren van onze diensten</li>
          <li>Communicatie over uw gebruik van de website</li>
        </ul>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">4. Cookies</h2>
        <p>
          We gebruiken alleen strikt noodzakelijke cookies voor het functioneren van de website.
          Deze cookies slaan geen persoonlijke informatie op.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">5. Beveiliging</h2>
        <p>
          We nemen redelijke maatregelen om uw gegevens te beschermen, maar kunnen geen absolute veiligheid garanderen.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">6. Disclaimer</h2>
        <p>
          Deze website wordt aangeboden "zoals deze is" zonder enige garanties. We zijn niet aansprakelijk voor eventuele schade
          die voortvloeit uit het gebruik van deze website.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-2xl font-semibold mb-4">7. Contact</h2>
        <p>
          Voor vragen over dit privacybeleid kunt u contact opnemen via:
          <a href="mailto:d.radic@roc-nijmegen.nl" class="text-purple-600 hover:text-purple-800">d.radic@roc-nijmegen.nl</a>
        </p>
      </section>
    </div>
  </div>
</template>