<template>
  <div class="bg-white shadow-md rounded-lg p-6">
    <div class="flex justify-between items-start">
      <div>
        <div class="flex items-center gap-2 mb-2">
          <h3 class="text-xl font-semibold">{{ job.title }}</h3>
          <span :class="[
            'px-2 py-1 text-sm rounded-full',
            job.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          ]">
            {{ job.isActive ? 'Active' : 'Inactive' }}
          </span>
        </div>
        <p class="text-gray-600 mb-1"><strong>Company:</strong> {{ job.company }}</p>
        <p class="text-gray-600 mb-1"><strong>Type:</strong> {{ job.type }}</p>
        <p class="text-gray-600 mb-1"><strong>Experience:</strong> {{ job.experience }}</p>
        <p class="text-gray-600 mb-1"><strong>Location:</strong> {{ job.location }}</p>
        <p class="text-gray-600 mb-1"><strong>Posted:</strong> {{ formatDate(job.postedOn) }}</p>
        <p class="text-gray-600 mb-1"><strong>Start Date:</strong> {{ formatDate(job.startDate) || 'Not specified' }}</p>
        <p class="text-gray-600 mb-1"><strong>End Date:</strong> {{ formatDate(job.endDate) || 'Not specified' }}</p>
        
        <div class="mt-4">
          <h4 class="font-semibold mb-2">Categories:</h4>
          <div class="flex flex-wrap gap-2">
            <span v-for="category in job.categories" :key="category" 
              class="bg-purple-100 text-purple-800 text-sm px-2 py-1 rounded">
              {{ category }}
            </span>
          </div>
        </div>

        <div class="mt-4">
          <h4 class="font-semibold mb-2">Skills:</h4>
          <div class="flex flex-wrap gap-2">
            <span v-for="skill in job.skills" :key="skill"
              class="bg-blue-100 text-blue-800 text-sm px-2 py-1 rounded">
              {{ skill }}
            </span>
          </div>
        </div>

        <div class="mt-4">
          <h4 class="font-semibold mb-2">About:</h4>
          <p class="text-gray-700">{{ job.about }}</p>
        </div>
      </div>
    </div>

    <div class="mt-6 flex gap-4">
      <template v-if="showActions">
        <button @click="$emit('approve', job.id)" 
          class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded">
          Approve
        </button>
        <button @click="$emit('reject', job.id)"
          class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">
          Reject
        </button>
      </template>
      <button @click="$emit('addKerntaken', job)"
        class="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded">
        {{ job.kerntaken ? 'Edit Kerntaken' : 'Add Kerntaken' }}
      </button>
    </div>

    <div v-if="job.kerntaken" class="mt-6">
      <h4 class="font-semibold mb-2">Assigned Kerntaken:</h4>
      <div class="space-y-2">
        <div v-for="(kerntaak, index) in job.kerntaken" :key="index"
          class="bg-gray-50 p-3 rounded">
          <p class="font-medium">{{ kerntaak.title }}</p>
          <p class="text-sm text-gray-600">{{ kerntaak.description }}</p>
        </div>
      </div>
    </div>

    <div v-if="job.feedback" class="mt-6">
      <h4 class="font-semibold mb-2">Feedback:</h4>
      <p class="text-gray-700 bg-yellow-50 p-3 rounded">{{ job.feedback }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobCard',
  
  props: {
    job: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    formatDate(timestamp) {
      if (!timestamp) return 'N/A'
      if (timestamp.toDate) {
        const date = timestamp.toDate()
        return date.toLocaleDateString('nl-NL', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      }
      return 'Invalid Date'
    }
  }
}
</script>