<template>
  <div class="max-w-7xl mx-auto px-4 py-8">
    <Breadcrumb :crumbs="[
      { name: 'Home', path: '/' },
      { name: 'Successen', path: '/blog' }
    ]" />

    <!-- Hero Section -->
    <div class="bg-gradient-to-r from-purple-800 to-indigo-700 rounded-lg shadow-xl p-8 mb-12">
      <div class="max-w-3xl mx-auto text-center">
        <h1 class="text-4xl font-bold text-white mb-4">Successen</h1>
        <p class="text-purple-100 text-lg mb-8">
          Ontdek de inspirerende verhalen en prestaties van onze studenten en partners
        </p>
        <div class="flex justify-center">
          <router-link
            v-if="isTeacher"
            to="/blog/create"
            class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-purple-700 bg-white hover:bg-purple-50 transition-colors duration-200"
          >
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            Nieuw Succes Toevoegen
          </router-link>
        </div>
      </div>
    </div>

    <!-- Search and Filters -->
    <div class="mb-8 space-y-4 bg-white rounded-lg shadow-md p-6">
      <div class="flex gap-4">
        <div class="relative flex-1">
          <input
            type="text"
            v-model="searchTerm"
            placeholder="Zoek in successen..."
            class="pl-10 w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          />
          <svg class="w-5 h-5 text-gray-400 absolute left-3 top-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        <button 
          @click="toggleShowInactive"
          :class="[
            'px-4 py-2 rounded-md font-medium transition-colors duration-200',
            showInactive 
              ? 'bg-red-100 text-red-800 hover:bg-red-200' 
              : 'bg-green-100 text-green-800 hover:bg-green-200'
          ]"
        >
          {{ showInactive ? 'Toon Actieve' : 'Toon Inactieve' }}
        </button>
      </div>

      <div class="flex gap-2 flex-wrap">
        <button
          v-for="category in availableCategories"
          :key="category"
          @click="toggleCategory(category)"
          :class="[
            'px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200',
            selectedCategories.includes(category)
              ? 'bg-purple-600 text-white'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          ]"
        >
          {{ category }}
        </button>
      </div>
    </div>

    <!-- Top 3 Section -->
    <div v-if="!showInactive && !searchTerm && selectedCategories.length === 0" class="mb-12">
      <h2 class="text-2xl font-bold mb-6 flex items-center">
        <svg class="w-6 h-6 text-yellow-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
        Top 3 Successen
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div 
          v-for="post in topPosts" 
          :key="post.id"
          class="group bg-gradient-to-br from-purple-50 to-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
        >
          <router-link :to="`/blog/${post.id}`">
            <div class="relative">
              <img
                v-if="post.coverImage"
                :src="post.coverImage"
                :alt="post.title"
                class="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
              />
              <div class="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </div>
            <div class="p-6">
              <h3 class="text-xl font-semibold mb-2 text-purple-800 group-hover:text-purple-600 transition-colors duration-200">
                {{ post.title }}
              </h3>
              <p class="text-gray-600 text-sm">
                {{ formatDate(post.createdAt) }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="text-center py-8">
      <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
      <p class="mt-2 text-gray-600">Laden...</p>
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
      {{ error }}
    </div>

    <!-- Empty State -->
    <div v-else-if="filteredPosts.length === 0" class="text-center py-8">
      <svg class="w-16 h-16 text-gray-400 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <p class="text-gray-600">Geen berichten gevonden.</p>
    </div>

    <!-- Posts Grid -->
    <div v-else class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
      <router-link
        v-for="post in filteredPosts"
        :key="post.id"
        :to="`/blog/${post.id}`"
        class="group bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
      >
        <div class="relative">
          <img
            v-if="post.coverImage"
            :src="post.coverImage"
            :alt="post.title"
            class="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
          <div class="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
        </div>
        <div class="p-6">
          <div class="flex justify-between items-start mb-2">
            <h2 class="text-xl font-semibold group-hover:text-purple-600 transition-colors duration-200">
              {{ post.title }}
            </h2>
            <span :class="[
              'px-2 py-1 text-sm rounded-full',
              post.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            ]">
              {{ post.isActive ? 'Actief' : 'Inactief' }}
            </span>
          </div>
          <p class="text-gray-600 text-sm mb-4">
            {{ formatDate(post.createdAt) }}
          </p>
          <div class="text-gray-500 text-sm">
            Door {{ post.authorEmail }}
          </div>
          <div class="mt-4 flex flex-wrap gap-2">
            <span v-for="category in post.categories" :key="category" 
              class="bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
              {{ category }}
            </span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { db } from '../firebase/config'
import { collection, query, orderBy, getDocs } from 'firebase/firestore'
import { useAuthStore } from '../stores/auth'
import Breadcrumb from '../components/Breadcrumb.vue'

const authStore = useAuthStore()
const isTeacher = computed(() => authStore.isTeacher)

const blogPosts = ref([])
const loading = ref(true)
const error = ref(null)
const searchTerm = ref('')
const showInactive = ref(false)
const selectedCategories = ref([])

const availableCategories = [
  'Techniek',
  'ICT',
  'Zorg',
  'Economie',
  'Sport',
  'Onderwijs',
  'Stage',
  'Project',
  'Event'
]

onMounted(async () => {
  try {
    const q = query(collection(db, 'blogPosts'), orderBy('createdAt', 'desc'))
    const querySnapshot = await getDocs(q)
    blogPosts.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      isActive: doc.data().isActive !== false // Default to true if not set
    }))
  } catch (err) {
    console.error('Error fetching blog posts:', err)
    error.value = 'Fout bij het laden van berichten. Probeer het later opnieuw.'
  } finally {
    loading.value = false
  }
})

const toggleCategory = (category) => {
  const index = selectedCategories.value.indexOf(category)
  if (index === -1) {
    selectedCategories.value.push(category)
  } else {
    selectedCategories.value.splice(index, 1)
  }
}

const toggleShowInactive = () => {
  showInactive.value = !showInactive.value
}

const filteredPosts = computed(() => {
  return blogPosts.value.filter(post => {
    const matchesSearch = post.title.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                         post.content.toLowerCase().includes(searchTerm.value.toLowerCase())
    const matchesCategories = selectedCategories.value.length === 0 || 
                             (post.categories && post.categories.some(cat => selectedCategories.value.includes(cat)))
    const matchesActiveState = post.isActive !== showInactive.value

    return matchesSearch && matchesCategories && matchesActiveState
  })
})

const topPosts = computed(() => {
  return blogPosts.value
    .filter(post => post.isActive)
    .slice(0, 3)
})

const formatDate = (timestamp) => {
  if (!timestamp) return 'N/A'
  if (timestamp.toDate) {
    const date = timestamp.toDate()
    return date.toLocaleDateString('nl-NL', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    })
  }
  return 'Invalid Date'
}
</script>