<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <!-- Simplified hero section with search -->
    <div class="flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <h1 class="text-4xl font-bold text-purple-800 mb-8">Jouw Toekomst</h1>
      <div class="w-full max-w-xl">
        <div class="flex mb-4">
          <input
            v-model="searchTerm"
            type="text"
            placeholder="Zoek jouw toekomst"
            class="flex-grow px-4 py-2 rounded-l-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            @input="filterJobs"
          />
          <button class="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-r-lg">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </button>
        </div>

        <!-- Advanced Search Toggle -->
        <div class="flex justify-end mb-4">
          <button 
            @click="showAdvancedSearch = !showAdvancedSearch"
            class="text-purple-600 hover:text-purple-800 text-sm font-medium"
          >
            {{ showAdvancedSearch ? 'Eenvoudig zoeken' : 'Geavanceerd zoeken' }}
          </button>
        </div>

        <!-- Advanced Search Options -->
        <div v-if="showAdvancedSearch" class="space-y-4 mb-8">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="relative">
              <select 
                v-model="selectedCategory"
                @change="filterJobs"
                class="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              >
                <option value="">Alle Categorieën</option>
                <option v-for="category in availableCategories" :key="category" :value="category">
                  {{ category }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </div>
            <div class="flex justify-end">
              <button 
                @click="toggleShowInactive"
                :class="[
                  'px-4 py-2 rounded-lg font-medium',
                  showInactive 
                    ? 'bg-red-100 text-red-800 hover:bg-red-200' 
                    : 'bg-green-100 text-green-800 hover:bg-green-200'
                ]"
              >
                {{ showInactive ? 'Toon Actieve Opdrachten' : 'Toon Inactieve Opdrachten' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Job listings -->
    <div class="mt-8">
      <h2 class="text-2xl font-bold mb-6 text-gray-800">
        {{ showInactive ? 'Inactieve Opdrachten' : 'Actieve Opdrachten' }}
      </h2>
      
      <div v-if="loading" class="text-center py-8">
        <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
        <p class="mt-2 text-gray-600">Opdrachten laden...</p>
      </div>

      <div v-else-if="error" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
        <p class="font-bold">Error</p>
        <p>{{ error }}</p>
      </div>

      <div v-else-if="filteredJobListings.length === 0" class="text-center py-8">
        <p class="text-gray-600">Geen opdrachten gevonden.</p>
      </div>

      <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div 
          v-for="job in filteredJobListings" 
          :key="job.id" 
          class="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          @click="goToJobDetails(job.id)"
        >
          <div class="relative pb-2/3">
            <img v-if="job.imageUrl" :src="job.imageUrl" :alt="job.company + ' logo'" class="absolute h-full w-full object-cover">
            <div v-else class="absolute h-full w-full bg-gray-200 flex items-center justify-center">
              <span class="text-gray-500 text-lg">No Image</span>
            </div>
          </div>
          <div class="p-4">
            <div class="flex items-center justify-between mb-2">
              <h3 class="text-xl font-semibold text-purple-800">{{ job.title }}</h3>
              <span :class="[
                'px-2 py-1 text-sm rounded-full',
                job.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              ]">
                {{ job.isActive ? 'Actief' : 'Inactief' }}
              </span>
            </div>
            <p class="text-gray-600 mb-2"><strong>Bedrijf:</strong> {{ job.company }}</p>
            <p class="text-gray-600 mb-2"><strong>Locatie:</strong> {{ job.location }}</p>
            <p class="text-gray-600 mb-2"><strong>Type:</strong> {{ job.type }}</p>
            <p class="text-gray-600 mb-2"><strong>Start Datum:</strong> {{ formatDate(job.startDate) || 'Niet gespecificeerd' }}</p>
            <p class="text-gray-600 mb-2"><strong>Eind Datum:</strong> {{ formatDate(job.endDate) || 'Niet gespecificeerd' }}</p>
            <div class="mt-4 flex flex-wrap gap-2">
              <span v-for="category in job.categories" :key="category" class="bg-purple-100 text-purple-800 text-sm font-medium px-2 py-1 rounded">
                {{ category }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { db } from '../firebase/config'
import { collection, query, orderBy, getDocs } from 'firebase/firestore'
import { useAuthStore } from '../stores/auth'
import { storeToRefs } from 'pinia'

const router = useRouter()
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)

const jobListings = ref([])
const loading = ref(true)
const error = ref('')
const searchTerm = ref('')
const selectedCategory = ref('')
const showInactive = ref(false)
const showAdvancedSearch = ref(false)

const availableCategories = [
  'Mbo',
  'Werken & Leren',
  'Mavo / havo / vwo',
  'Web Development',
  'Mobile Development',
  'Data Science',
  'Machine Learning',
  'DevOps',
  'UI/UX Design',
  'Elektro',
  'Metaalbewerking',
  'Autotechniek'
]

onMounted(async () => {
  try {
    const q = query(
      collection(db, 'jobListings'),
      orderBy('postedOn', 'desc')
    )
    const querySnapshot = await getDocs(q)
    jobListings.value = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(job => job.approved !== false)
  } catch (err) {
    console.error('Error fetching job listings:', err)
    error.value = 'Failed to load job listings. Please try again later.'
  } finally {
    loading.value = false
  }
})

const toggleShowInactive = () => {
  showInactive.value = !showInactive.value
}

const filteredJobListings = computed(() => {
  return jobListings.value.filter(job => {
    const matchesSearch = job.title.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                         job.company.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                         job.location.toLowerCase().includes(searchTerm.value.toLowerCase())
    const matchesCategory = !selectedCategory.value || 
                           (job.categories && job.categories.includes(selectedCategory.value))
    const matchesActiveState = job.isActive !== showInactive.value
    
    return matchesSearch && matchesCategory && matchesActiveState
  })
})

const filterJobs = () => {
  // This function is called when the search term or category changes
  // The filtering is handled by the computed property
}

const formatDate = (timestamp) => {
  if (!timestamp) return 'N/A'
  if (timestamp.toDate) {
    const date = timestamp.toDate()
    return date.toLocaleDateString('nl-NL', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    })
  }
  return 'Invalid Date'
}

const goToJobDetails = (jobId) => {
  router.push({ name: 'JobDetails', params: { id: jobId } })
}
</script>

<style scoped>
.pb-2\/3 {
  padding-bottom: 66.666667%;
}
</style>