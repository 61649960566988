<template>
  <div v-if="!hasConsent" class="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 z-50">
    <div class="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
      <p class="text-gray-700">
        Deze website gebruikt cookies om uw ervaring te verbeteren. Door gebruik te maken van deze website gaat u akkoord met ons
        <router-link to="/privacy" class="text-purple-600 hover:text-purple-800">privacybeleid</router-link>.
      </p>
      <button
        @click="acceptCookies"
        class="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 whitespace-nowrap"
      >
        Akkoord
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const hasConsent = ref(false)

onMounted(() => {
  hasConsent.value = localStorage.getItem('cookieConsent') === 'true'
})

const acceptCookies = () => {
  localStorage.setItem('cookieConsent', 'true')
  hasConsent.value = true
}
</script>