export function getYoutubeVideoId(url) {
  if (!url) return null
  
  try {
    // Handle youtu.be URLs
    if (url.includes('youtu.be/')) {
      return url.split('youtu.be/')[1].split('?')[0]
    }
    
    // Handle standard youtube.com URLs
    const standardMatch = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)
    if (standardMatch) {
      return standardMatch[1]
    }

    return null
  } catch (e) {
    console.error('Error parsing YouTube URL:', e)
    return null
  }
}

export function getYoutubeThumbnailUrl(videoId) {
  if (!videoId) return null
  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}

export function getYoutubeWatchUrl(videoId) {
  if (!videoId) return null
  return `https://www.youtube.com/watch?v=${videoId}`
}

export function getYoutubeEmbedUrl(url) {
  const videoId = getYoutubeVideoId(url)
  if (!videoId) return null
  return `https://www.youtube.com/embed/${videoId}`
}