export const softwareDevelopmentKerntaken = {
  kerntaken: [
    {
      id: 'realiseert-software',
      title: 'Realiseert software',
      description: 'Ontwikkelt software volgens specificaties en richtlijnen',
      subItems: {
        complexiteit: 'Werkt met complexe software ontwikkelingsprojecten die gebruik maken van verschillende technologieën en frameworks',
        verantwoordelijkheid: 'Is verantwoordelijk voor de ontwikkeling en implementatie van software componenten',
        vakkennis: [
          'Plant werkzaamheden en bewaakt de voortgang',
          'Ontwerpt software',
          'Realiseert (onderdelen van) software',
          'Test software',
          'Doet verbetervoorstellen voor de software'
        ]
      }
    },
    {
      id: 'werkt-ontwikkelteam',
      title: 'Werkt in een ontwikkelteam',
      description: 'Functioneert effectief in een ontwikkelteam en draagt bij aan het teamresultaat',
      subItems: {
        complexiteit: 'Werkt in verschillende teamstructuren en past zich aan aan verschillende werkwijzen',
        verantwoordelijkheid: 'Is verantwoordelijk voor eigen bijdrage aan het team en communiceert effectief',
        vakkennis: [
          'Voert overleg',
          'Presenteert het opgeleverde werk',
          'Reflecteert op het werk',
          'Past Agile/Scrum methodieken toe',
          'Werkt samen in multidisciplinaire teams'
        ]
      }
    }
  ]
}