<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h1 class="text-3xl font-bold mb-6 text-purple-800">Bedrijven</h1>
    
    <!-- Updated description section -->
    <div class="bg-white shadow rounded-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Voor Bedrijven</h2>
      <p class="text-gray-700 mb-4">
        Welkom bij het ROC Nijmegen bedrijvenportaal. Wij bieden een unieke kans om samen te werken met toekomstige professionals en bij te dragen aan hun ontwikkeling.
      </p>
      <h3 class="text-xl font-semibold mb-2 text-purple-700">Wat kunt u doen?</h3>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Projecten en stageopdrachten plaatsen voor onze studenten</li>
        <li>Vacatures voor afgestudeerden publiceren</li>
        <li>Samenwerken met onze onderwijsinstellingen aan innovatieve projecten</li>
        <li>Deelnemen aan carrière-evenementen en gastcolleges geven</li>
        <li>Uw expertise delen en bijdragen aan curriculum ontwikkeling</li>
      </ul>
      <h3 class="text-xl font-semibold mb-2 text-purple-700">Waarom samenwerken met ROC Nijmegen?</h3>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Toegang tot een pool van gemotiveerd en opgeleid talent</li>
        <li>Mogelijkheid om toekomstige werknemers vroeg te identificeren en te ontwikkelen</li>
        <li>Bijdragen aan de ontwikkeling van relevante vaardigheden in uw industrie</li>
        <li>Versterken van uw bedrijfsimago als een organisatie die investeert in onderwijs</li>
        <li>Netwerkmogelijkheden met andere bedrijven en onderwijsinstellingen</li>
      </ul>
      <p class="text-gray-700 mb-4">
        Door samen te werken met ROC Nijmegen, draagt u niet alleen bij aan de ontwikkeling van toekomstige professionals, maar versterkt u ook uw band met lokaal talent en vergroot u de zichtbaarheid van uw bedrijf in de regio.
      </p>
      <router-link to="/post-job" class="mt-6 inline-block bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition duration-300">
        Project of Vacature Plaatsen
      </router-link>
    </div>

    <h2 class="text-2xl font-semibold mb-6 text-purple-800">Onze Partner Bedrijven</h2>
    <div v-if="loading" class="text-center">
      <p class="text-xl">Bedrijven laden...</p>
    </div>
    <div v-else-if="error" class="text-center text-red-600">
      <p>{{ error }}</p>
    </div>
    <div v-else-if="companies.length === 0" class="text-center">
      <p>Er zijn momenteel geen partnerbedrijven om weer te geven.</p>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div v-for="company in companies" :key="company.id" class="bg-white shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-2 text-purple-700">{{ company.name }}</h2>
        <p class="text-gray-600 mb-4">{{ company.description }}</p>
        <div class="flex justify-between items-center">
          <span class="text-sm text-gray-500">{{ company.location }}</span>
          <a :href="company.website" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800">Website</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/config'

export default {
  setup() {
    const companies = ref([])
    const loading = ref(true)
    const error = ref('')

    onMounted(async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'companies'))
        companies.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (err) {
        console.error('Error fetching companies:', err)
        error.value = 'Fout bij het laden van bedrijven. Probeer het later opnieuw.'
      } finally {
        loading.value = false
      }
    })

    return {
      companies,
      loading,
      error
    }
  }
}
</script>