<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <h1 class="text-3xl font-bold mb-6 text-purple-800">Over ROC Nijmegen</h1>
    
    <div class="bg-white shadow rounded-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Onze Missie</h2>
      <p class="text-gray-700 mb-4">
        Bij ROC Nijmegen streven we ernaar om studenten de beste voorbereiding te bieden op hun toekomstige carrière en persoonlijke ontwikkeling. Wij geloven in praktijkgericht onderwijs dat nauw aansluit bij de behoeften van het bedrijfsleven en de samenleving.
      </p>
    </div>

    <div class="bg-white shadow rounded-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Onze Visie</h2>
      <p class="text-gray-700 mb-4">
        We zien een toekomst waarin elk individu de kans krijgt om zijn of haar volledige potentieel te benutten. Door innovatief onderwijs, nauwe samenwerking met het bedrijfsleven en persoonlijke begeleiding, streven we ernaar om onze studenten klaar te stomen voor de uitdagingen van morgen.
      </p>
    </div>

    <div class="bg-white shadow rounded-lg p-6 mb-8">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Onze Waarden</h2>
      <ul class="list-disc list-inside text-gray-700 mb-4">
        <li>Kwaliteit: We streven naar excellentie in alles wat we doen</li>
        <li>Innovatie: We omarmen nieuwe ideeën en technologieën</li>
        <li>Inclusiviteit: We waarderen diversiteit en creëren een welkomende omgeving voor iedereen</li>
        <li>Samenwerking: We geloven in de kracht van partnerschap met studenten, docenten en het bedrijfsleven</li>
        <li>Duurzaamheid: We nemen verantwoordelijkheid voor onze impact op de wereld</li>
      </ul>
    </div>

    <div class="bg-white shadow rounded-lg p-6">
      <h2 class="text-2xl font-semibold mb-4 text-purple-800">Onze Geschiedenis</h2>
      <p class="text-gray-700 mb-4">
        ROC Nijmegen heeft een rijke geschiedenis die teruggaat tot [JAAR]. Sinds onze oprichting hebben we duizenden studenten opgeleid en bijgedragen aan de economische en sociale ontwikkeling van de regio Nijmegen. We blijven ons voortdurend aanpassen aan de veranderende behoeften van onze studenten en de arbeidsmarkt, terwijl we trouw blijven aan onze kernwaarden en toewijding aan kwaliteitsonderwijs.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverOnsPage'
}
</script>